@font-face {
  font-family: 'Gotham Book';
  src: url('../assets/fonts/GothamBookRegular.otf') format('opentype');
  font-style: 'normal';
}

@font-face {
  font-family: 'Avenir';
  src: url('../assets/fonts/AvenirLTStd/AvenirLTStd-Medium.otf')
    format('opentype');
  font-style: 'normal';
}

@font-face {
  font-family: 'Avenir Light';
  src: url('../assets/fonts/AvenirLTStd/AvenirLTStd-Light.otf')
    format('opentype');
  font-style: 'normal';
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Gotham Book', 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

video#background-video {
  position: fixed;
  top: 50%;
  left: 50%;
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  -ms-transform: translateX(-50%) translateY(-50%);
  -moz-transform: translateX(-50%) translateY(-50%);
  -webkit-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
  /* background: url('assets/WCITPA_003_45sec_Final.png') no-repeat; */
  background-size: cover;
  z-index: -100;
}
